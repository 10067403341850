<template>
    <b-card no-body>
        <b-card-header>
            <strong>Instructor/Mentor Directory</strong>
        </b-card-header>
        <b-card-body>
            <b-button size="sm" variant="dark" @click="getPdf">Generate PDF</b-button>
        </b-card-body>
    </b-card>
</template>


<script>
import { Component, Vue } from 'vue-property-decorator';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import download from '@/util/downloader';
import {errorModalOptions} from "@/util/formatters";

@Component
export default class MentorInstructorDirectory extends Vue {

    async mounted() {
        this.$store.commit('addBreadcrumb', Breadcrumb.create('Instructor\/Mentor Directory', null, true));
    }

    async getPdf() {
        try {
            await download('POST', '/api/v1/report/mentor-instructor-directory', '');
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

}
</script>


<style scoped>

</style>
